// extracted by mini-css-extract-plugin
export var cdBreadcrumbs = "coworkers-module--cd-breadcrumbs--EJlje";
export var cdCoworker = "coworkers-module--cd-coworker--cF4P+";
export var cdCoworkerCard = "coworkers-module--cd-coworker-card--KDWVB";
export var cdCoworkerCardGrid = "coworkers-module--cd-coworker-card-grid--n5BG7";
export var cdCoworkerCardImg = "coworkers-module--cd-coworker-card-img--6KceP";
export var cdCoworkerContent = "coworkers-module--cd-coworker-content--xNkh6";
export var cdCoworkerContentBox = "coworkers-module--cd-coworker-content-box--szEpN";
export var cdCoworkerContentTop = "coworkers-module--cd-coworker-content-top--DEnh1";
export var cdCoworkerGrid = "coworkers-module--cd-coworker-grid--s415H";
export var cdCoworkerGridWrapper = "coworkers-module--cd-coworker-grid-wrapper--jM6ao";
export var cdCoworkerHero = "coworkers-module--cd-coworker-hero--peoLW";
export var cdCoworkerHeroContent = "coworkers-module--cd-coworker-hero-content--OhwFw";
export var cdCoworkerImg = "coworkers-module--cd-coworker-img--1AepP";
export var cdCoworkerImgWrapper = "coworkers-module--cd-coworker-img-wrapper--ZZhi-";
export var cdCoworkerSection = "coworkers-module--cd-coworker-section--vjqyw";
export var cdCoworkerText = "coworkers-module--cd-coworker-text--uJhB1";
export var cdSingleCoworker = "coworkers-module--cd-single-coworker--goZEN";