// Dependency
import * as React from "react"
import Fade from 'react-reveal/Fade';
import {graphql, Link} from "gatsby";
import Img from "gatsby-image";

// Components
import Layout from "../../components/layouts";
import CoworkerNav from "../../components/coworker-nav/coworker-nav";

// Styles
import * as coworkersStyles from './coworkers.module.scss';

// Internationalization
import { useTranslation } from 'react-i18next';

// Assets


// GraphQL to get Dato data
export const query = graphql`
query ($slug: String!, $locale: String!){
    single: allDatoCmsCoworker(
      filter: {slug: { eq: $slug }, locale: {eq: $locale}}
    )
    {
      nodes {
        name
        title
        slug
        email
        phone
        linkedIn
        content
        education
        educationImage{
          fluid{
            ...GatsbyDatoCmsFluid
          }
        }
        image{
          fluid{
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    all: allDatoCmsCoworker (
      filter: {locale: {eq: $locale}}
    )
    {
      nodes {
        name
        title
        slug
        image{
          fluid{
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`

// Template
const CoworkerSingle = (props) => {
    const pageData = props.data.single.nodes;

    const metaData = {
        name: pageData[0].name,
        image:{
            src: pageData[0].image,
        }
    }

    const { t } = useTranslation();

    return (
        <section>
            <Layout data={metaData} path={props.location} locale={props.pageContext.locale}>
                <section className={coworkersStyles.cdSingleCoworker}>
                    <div className={`cd-max-width`}>
                        <div className={coworkersStyles.cdBreadcrumbs}>
                            <Link to={`/${props.pageContext.locale}/coworkers`}>{t('coworker.coworker')}</Link>
                            <span>·</span>
                            <span>{pageData[0].name}</span>
                        </div>
                        <div className={coworkersStyles.cdCoworkerGrid}>
                            <div className={coworkersStyles.cdCoworkerImgWrapper}>
                                <div className={`${coworkersStyles.cdCoworkerImg} cd-background-img`}>
                                    <Img fluid={pageData[0].image.fluid} alt={pageData[0].name} />
                                </div>
                            </div>
                            <div className={coworkersStyles.cdCoworkerContent}>
                                <div className={coworkersStyles.cdCoworkerText}>
                                    <div className={coworkersStyles.cdCoworkerContentTop}>
                                        <Fade bottom>
                                            <span>{pageData[0].title}</span>
                                            <h2>{pageData[0].name}</h2>
                                            <a href={`mailto:${pageData[0].email}`}>{pageData[0].email}</a>
                                            <p>{pageData[0].phone}</p>
                                            <a target="_blank" rel="noreferrer" href={pageData[0].linkedIn}>LinkedIn</a>
                                        </Fade>
                                    </div>
                                    <Fade bottom>
                                        <div dangerouslySetInnerHTML={{__html: pageData[0].content}}></div>
                                    </Fade>
                                    {
                                        pageData[0] &&  pageData[0].education && <Fade bottom>
                                            <div className={coworkersStyles.cdCoworkerContentBox}>
                                                {
                                                    pageData[0] &&  pageData[0].educationImage && <Img fluid={pageData[0].educationImage.fluid} alt="" />
                                                }
                                                <p>{pageData[0].education}</p>
                                            </div>
                                        </Fade>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CoworkerNav locale={props.pageContext.locale} data={props.data.all.nodes} />

            </Layout>
        </section>
    )
}

export default CoworkerSingle
