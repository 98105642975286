// Dependency
import React, { Component } from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "gatsby";

import Img from "gatsby-image";

// Styles
import * as navStyles from './coworker-nav.module.scss'
import 'swiper/swiper.scss';

// Assets

// Template
export default class CoworkerNav extends Component {

    render() {
        return (
            <section className={navStyles.cdCoworkerNavWrapper} id="cd-coworker-nav-wrapper">
                <div className={navStyles.cdCoworkerNav} id="cd-coworker-nav">
                    <div className="cd-max-width">
                        <Swiper className={`cd-swiper`}
                                spaceBetween={40}
                                slidesPerView={'auto'}
                                slidesPerGroup={1}
                                grabCursor={true}
                                watchSlidesVisibility={true}
                        >
                            {this.props.data.map( (coworkerItem) => {
                                return (
                                    <SwiperSlide className={navStyles.swiperSlide}>
                                        <Link to={`/${this.props.locale}/coworker/${coworkerItem.slug}`} className={navStyles.cdCoworkerNavItem}>
                                            <div className={`${navStyles.cdCoworkerNavItemImg} cd-background-img`}>
                                                <Img fluid={coworkerItem.image.fluid} alt={coworkerItem.name} />
                                            </div>
                                            <div className={navStyles.cdCoworkerNavItemContent}>
                                                <p>{coworkerItem.name}</p>
                                                <span>{coworkerItem.title}</span>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                )
                            } )}
                        </Swiper>
                    </div>
                </div>
            </section>
        );
    }
}
